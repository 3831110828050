import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import { Button, Input } from "antd";
import { AddFundToWallet, checkStatus } from "./AddWalletEndpoint";
import { dispatchtoast, getLocalStorageItem } from "../../../Utils";

const AddWalletBal = () => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(null);
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    dispatch(setselectedTab("106"));
  }, []);
  const makePayment = (data, data1) => {
    var config = {
      root: "",
      style: {
        bodyBackgroundColor: "#fafafb",
        bodyColor: "",
        themeBackgroundColor: "#0FB8C9",
        themeColor: "#ffffff",
        headerBackgroundColor: "#284055",
        headerColor: "#ffffff",
        errorColor: "",
        successColor: "",
        card: {
          padding: "",
          backgroundColor: "",
        },
      },
      data: {
        orderId: data1.orderId,
        token: data?.body?.txnToken,
        tokenType: "TXN_TOKEN",
        amount: amount /* update amount */,
      },
      payMode: {
        labels: {},
        filter: {
          exclude: [],
        },
        order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
      },
      website: "WEBSTAGING",
      flow: "DEFAULT",
      merchant: {
        mid: data1.MID,
        redirect: false,
      },
      handler: {
        transactionStatus: function transactionStatus(paymentStatus) {
          if (paymentStatus?.STATUS === "TXN_SUCCESS") {
            window.Paytm.CheckoutJS.close();
            checkStatus({
              CHECKSUMHASH: data.head?.signature,
              STATUS: paymentStatus?.STATUS,
              transaction_id: data1?.orderId,
            })
              .then((res) => {
                if (res.status) {
                  dispatchtoast(res.message);
                  setAmount(null)
                }
              })
              .catch((err) => console.log(err));
          }
        },
        notifyMerchant: function notifyMerchant(eventName, data) {
          console.log("Closed");
        },
      },
    };
    try {
      if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            setSpinner(false)
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error) {
            console.log("Error => ", error);
          });
      }
    } catch (error) {
      dispatchtoast(error.message, true);
    }
  };

  const handleAdd = () => {
    setSpinner(true);
    const payload = {
      amount: amount,
      latitude: getLocalStorageItem("latitude"),
      longitude: getLocalStorageItem("longitude"),
    };
    AddFundToWallet(payload)
      .then((res) => {
        if (res.status) {
          makePayment(JSON.parse(res.request_data.response), res.request_data);
        }
      })
      .catch((err) => console.log(err))
  };
  return (
    <>
      <div className="p-4 bg-white rounded-md">
        <div className="flex justify-start items-center gap-3">
          <Input
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full h-10 md:w-1/2 lg:w-1/4 outline-gray-500 text-xs"
            placeholder="Enter amount"
          />
          <Button
            loading={spinner}
            onClick={() =>
              amount ? handleAdd() : dispatchtoast("Add some amount", true)
            }
            className="bg-primary h-10 rounded-none border-none text-white"
          >
            Add Fund
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddWalletBal;
