import { getAPIUrl, Post } from "../../../ApiServices/apiMethod";

export const OfflineEndPoint = {
  genertaeOfflinePan: "Api/V1/OfflineNsdl/generateNewPanReqest",
  correctOfflinePan: "Api/V1/OfflineNsdl/generateCorrectionRequest",
  OfflineNsdlReport:"Api/V1/OfflineNsdl/nsdlReport",

};

export const generateOfflinePan = (data, param = "") => {
  const url = getAPIUrl(OfflineEndPoint.genertaeOfflinePan, param);
  return Post(url, data);
};
export const correctOfflinePan = (data, param = "") => {
  const url = getAPIUrl(OfflineEndPoint.correctOfflinePan, param);
  return Post(url, data);
};
export const OfflineNsdlReportData = (data,param = "") => {
  const url = getAPIUrl(OfflineEndPoint.OfflineNsdlReport, param);
  return Post(url,data);
};
