import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import {
  aadharPattern,
  dispatchtoast,
  getLocalStorageItem,
  panPattern,
} from "../../../Utils";
import { AiOutlineBank } from "react-icons/ai";

import { SubmitPanDetails } from "../../../ApiServices/Apifun";
import { useForm } from "antd/es/form/Form";
import { setselectedTab } from "../../../Store/B2bslice";
import { useDispatch, useSelector } from "react-redux";
import CommonInput from "../../../Common/CommonInput";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Content from "../../../Common/ReceiptContent";
import ReportDrawer from "../../../Common/ReportDrawer";

const PanDetails = () => {
  const [form] = useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [pan, setPan] = useState(null);
  const [Details, setDetails] = useState(null);
  const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const [receiptData, setReceiptData] = useState({
    data: null,
    transaction_id: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const memberServices = useSelector((state) => state.B2B.memberServices);
  useEffect(() => {
    dispatch(setselectedTab("105"));
    if (!memberServices.includes("102")) {
      navigate("/not-Available");
    }
  }, []);
  const handleFinish = (val) => {
    setBtnLoading(true);
    setDetails(null);
    const formdata = new FormData();
    formdata.append("pan_number", val.pan_number);
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));

    SubmitPanDetails(formdata)
      .then((res) => {
        if (res.status) {
          setReceiptData({
            data: {
              Name: res.data.name,
              "Father's Name": res.data.father_name
                ? res.data.father_name
                : "-",
              "Pan No.": res.data.pan_no,
              Gender: res.data.gender,
              DOB: res.data.dob ? res.data.dob : "-",
            },
            transaction_id: res.data.transaction_id,
          });
          setOpenReceiptDrawer(true);
          setPan(res.data.pan_no);
          setDetails(res.data);
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2  bg-white shadow-normal  rounded-3xl p-5">
          <p className="font-bold text-[15px] mb-2">Pan Verification</p>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handleFinish(val)}
          >
            <div className="w-full grid grid-cols-1   gap-x-8 place-items-start">
              <Form.Item
                name={`pan_number`}
                label={"Pan Number"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter pan number!",
                  },
                  {
                    pattern: panPattern,
                    message: "Please enter valid pan no.",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput placeholder="Enter Pan No." name="pan_number" />
              </Form.Item>{" "}
            </div>
            {Details && (
              <div className="bg-primary p-2 font-bold rounded-md text-white">
                <p>Pan No.- {Details?.pan_no ?? "Not Defined"}</p>
                <p>Name- {Details?.name ?? "Not Defined"}</p>
                <p>Gender- {Details?.gender ?? "Not Defined"}</p>
                <p>DOB- {Details?.dob ?? "Not Defined"}</p>
                {!(
                  Details?.father_name == "" || Details.father_name == null
                ) && (
                  <p>father Name- {Details?.father_name ?? "Not Defined"}</p>
                )}
              </div>
            )}
            <div className="flex justify-end items-center ">
              <Form.Item>
                <Button
                  loading={btnLoading}
                  className="w-[200px] bg-primary border-none mt-4"
                  style={{ color: "white" }}
                  htmlType="submit"
                >
                  Send
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      {openReceiptDrawer && (
        <ReportDrawer
          title={"Pan Detail Receipt"}
          open={openReceiptDrawer}
          setOpen={setOpenReceiptDrawer}
          content={<Content data={receiptData} />}
        />
      )}
    </>
  );
};

export default PanDetails;
