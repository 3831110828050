import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import {
  aadharPattern,
  dispatchtoast,
  getLocalStorageItem,
} from "../../../Utils";

import { SubmitAdharToPan } from "../../../ApiServices/Apifun";
import { useForm } from "antd/es/form/Form";
import { setselectedTab } from "../../../Store/B2bslice";
import { useDispatch, useSelector } from "react-redux";
import CommonInput from "../../../Common/CommonInput";
import { useNavigate } from "react-router-dom";
import ReportDrawer from "../../../Common/ReportDrawer";
import Content from "../../../Common/ReceiptContent";

const AdharToPan = () => {
  const [form] = useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [pan, setPan] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const [receiptData, setReceiptData] = useState({
    data: null,
    transaction_id: null,
  });
  const memberServices = useSelector((state) => state.B2B.memberServices);
  useEffect(() => {
    dispatch(setselectedTab("155"));
    if (!memberServices.includes("101")) {
      navigate("/not-Available");
    }
  }, []);
  const handleFinish = (val) => {
    setBtnLoading(true);
    setPan(null);
    const formdata = new FormData();
    formdata.append("aadhaar_number", val.aadhaar_number);
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));

    SubmitAdharToPan(formdata)
      .then((res) => {
        if (res.status) {
          setReceiptData({
            data: {
              "Pan No.": res.data.pan_no,
              "Aadhar Number": res.data.aadhar_no,
            },
            transaction_id: res.data.transaction_id,
          });
          setOpenReceiptDrawer(true);
          setPan(res.data.pan_no);
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2  bg-white shadow-normal  rounded-3xl p-5">
          <p className="font-bold text-[15px] mb-2">Get Adhar To Pan Data</p>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handleFinish(val)}
          >
            <div className="w-full grid grid-cols-1   gap-x-8 place-items-start">
              <Form.Item
                name={`aadhaar_number`}
                label={"Adhar Number"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter adhar number!",
                  },
                  {
                    pattern: aadharPattern,
                    message: "Please enter valid aadhar no.",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  onInput={(e) => {
                    let value = e.target.value.replace(/\D/g, "");
                    if (value.length > 12) {
                      value = value.slice(0, 12);
                    }
                    e.target.value = value;
                  }}
                  placeholder="Enter Adhar No."
                  name="aadhaar_number"
                />
              </Form.Item>{" "}
            </div>
            {pan && (
              <div className="bg-primary p-2 font-bold rounded-md text-white">
                <p>Pan No.- {pan ?? "Not Defined"}</p>
              </div>
            )}
            <div className="flex justify-end items-center ">
              <Form.Item>
                <Button
                  loading={btnLoading}
                  className="w-[200px] bg-primary border-none mt-4"
                  style={{ color: "white" }}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      {openReceiptDrawer && (
        <ReportDrawer
          title={"Aadhar to pan Receipt"}
          open={openReceiptDrawer}
          setOpen={setOpenReceiptDrawer}
          content={<Content data={receiptData} />}
        />
      )}
    </>
  );
};

export default AdharToPan;
