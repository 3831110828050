import { getAPIUrl, Post } from "../../../ApiServices/apiMethod";

export const AddWalletEndpoint = {
  AddFundWallet: "api/V1/AddFund/AddFundWallet",
  AddFundResponse: "api/V1/AddFund/paytmPayment_response",
  addFundReport:"/api/V1/AddFund/addfundReport"
};

export const AddFundToWallet = (data,param = "") => {
  const url = getAPIUrl(AddWalletEndpoint.AddFundWallet, param);
  return Post(url, data);
};
export const checkStatus = (data,param = "") => {
  const url = getAPIUrl(AddWalletEndpoint.AddFundResponse, param);
  return Post(url, data);
};

export const FetchaddFundReport = (data,param = "") => {
  const url = getAPIUrl(AddWalletEndpoint.addFundReport, param);
  return Post(url, data);
};