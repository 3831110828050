import React, { useEffect } from "react";
import { trimString } from "../../../Utils";
import { useDispatch } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";

const OfflineLinks = () => {
  const dispatch=useDispatch()
  useEffect(()=>{
dispatch(setselectedTab("32"))
  },[])
  const links = [
    {
      label: "IndusInd bank",
      link: "https://myaccount.indusind.com/SavingsAccount/index.aspx?utm_source=Website&utm_medium=Header-Navigation&utm_campaign=mktgothers",
    },
    {
      label: "KOTAK MAHINDRA BANK",
      link: "https://www.kotak811.com/open-zero-balance-savings-account?source=GoogleSEMiQ&banner=account-desktop_brand_kotak_mahindra_exact&pubild=kotak+mahindra+bank&gclid=CjwKCAiAq8f-BRBtEiwAGr3DgRDKvpKSX_RpZBZczwIavrXKEnS7_ZNyXnlCKu6sayPSQbqFzqXjdBoCfbgQAvD_BwE",
    },
    {
      label: "Bank of Baroda",
      link: "https://www.bankofbaroda.in/personal-banking/accounts/saving-accounts",
    },
    {
      label: "RBL bank",
      link: "https://abacus.rblbank.com/fe-dsa/savings#/welcome",
    },
  ];
  return (
    <>
      <div className="w-full">
        <p className="text-lg font-semibold">Account Opening</p>
        {links.map((i, index) => (
          <div
            key={index}
            className="grid grid-cols-1 md:grid-cols-2 w-full place-items-start my-3 bg-primary text-white p-3 rounded-md font-semibold cursor-pointer transition-all hover:scale-y-105 "
          >
            <p>{i.label}</p>
            <a
              href={i.link}
              target="_blank"
              style={{ wordBreak: "break-word" }}
              className="decoration-inherit hover:text-white"
            >
              {trimString(i.link, 100)}
            </a>
          </div>
        ))}
        <p className="text-lg font-semibold">IRCTC</p>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full place-items-start my-3 bg-primary text-white p-3 rounded-md font-semibold cursor-pointer transition-all hover:scale-y-105 ">
          <p>IRCTC</p>
          <a
            href={"https://www.irctc.co.in/nget/train-search"}
            target="_blank"
            style={{ wordBreak: "break-word" }}
            className="decoration-inherit hover:text-white"
          >
            {trimString("https://www.irctc.co.in/nget/train-search", 100)}
          </a>
        </div>
        <p className="text-lg font-semibold">Addhar correction</p>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full place-items-start my-3 bg-primary text-white p-3 rounded-md font-semibold cursor-pointer transition-all hover:scale-y-105 ">
          <p>Addhar correction</p>
          <a
            href={"https://www.uidai.gov.in/"}
            target="_blank"
            style={{ wordBreak: "break-word" }}
            className="decoration-inherit hover:text-white"
          >
            {trimString("https://www.uidai.gov.in/", 100)}
          </a>
        </div>
        <p className="text-lg font-semibold">Aadhar Download</p>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full place-items-start my-3 bg-primary text-white p-3 rounded-md font-semibold cursor-pointer transition-all hover:scale-y-105 ">
          <p>Aadhar Download</p>
          <a
            href={"https://uidai.gov.in/en/my-aadhaar/get-aadhaar.html"}
            target="_blank"
            style={{ wordBreak: "break-word" }}
            className="decoration-inherit hover:text-white"
          >
            {trimString("https://uidai.gov.in/en/my-aadhaar/get-aadhaar.html", 100)}
          </a>
        </div>
        <p className="text-lg font-semibold">Voter I'd card</p>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full place-items-start my-3 bg-primary text-white p-3 rounded-md font-semibold cursor-pointer transition-all hover:scale-y-105 ">
          <p>Voter I'd card</p>
          <a
            href={"https://voterportal.eci.gov.in/"}
            target="_blank"
            style={{ wordBreak: "break-word" }}
            className="decoration-inherit hover:text-white"
          >
            {trimString("https://voterportal.eci.gov.in/", 100)}
          </a>
        </div>
      </div>
    </>
  );
};

export default OfflineLinks;
