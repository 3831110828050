import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import { Button, DatePicker, Form, Select, Upload } from "antd";
import CommonInput from "../../../Common/CommonInput";
import {
  aadharPattern,
  dispatchtoast,
  emailPattern,
  mobilePattern,
  panPattern,
} from "../../../Utils";
import {
  correctOfflinePan,
  generateOfflinePan,
} from "./OfflineServiceEndpoint";
import dayjs from "dayjs";
import { UploadOutlined } from "@ant-design/icons";

const OfflinePanService = () => {
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  const [fileData, setFileData] = useState({
    new: null,
    correction: null,
  }); // Holds base64 file data
  const [fileName, setFileName] = useState({
    new: null,
    correction: null,
  }); // Holds uploaded file name
  const Tabs = [
    {
      key: 1,
      name: "Generate New Pan",
    },
    {
      key: 2,
      name: "Correction Pan",
    },
  ];
  useEffect(() => {
    dispatch(setselectedTab("160"));
  }, []);
  const handleGenerate = (val) => {
    let temp = { ...val };
    temp["dob"] = dayjs(temp.dob).format("DD.MM.YYYY");
    temp["document_file"] = fileData.new;
    const formdata = new FormData();
    Object.keys(temp).map((key) => formdata.append(key, temp[key]));
    generateOfflinePan(temp)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCorrection = (val) => {
    let temp = { ...val };
    temp["dob"] = dayjs(temp.dob).format("DD/MM/YYYY");
    temp["document_file"] = fileData.correction;
    const formdata = new FormData();
    Object.keys(temp).map((key) => formdata.append(key, temp[key]));
    correctOfflinePan(temp)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleBeforeUpload = (file, from) => {
    const isAllowedFormat =
      file.type === "application/pdf" || file.type === "application/msword";

    if (!isAllowedFormat) {
      message.error("Only PDF or DOC files are allowed!");
      return Upload.LIST_IGNORE; // Prevents the upload if file type is not allowed
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      from === "new"
        ? setFileData({ ...fileData, new: reader.result })
        : setFileData({ ...fileData, correction: reader.result }); // Convert file to base64 and store
      from === "new"
        ? setFileName({ ...fileData, new: file.name })
        : setFileName({ ...fileData, correction: file.name }); // Store file name for preview
    };

    return false; // Prevents automatic upload
  };
  return (
    <>
      <div className="mt-8  bg-white rounded-lg grid grid-cols-1 md:grid-cols-2 place-items-start md:place-items-center gap-y-4">
        {Tabs.map((i, index) => {
          return (
            <div className="w-full text-center">
              <p
                onClick={() => {
                  setCurrent(index);
                }}
                className={`cursor-pointer transition-all  ${
                  i.key - 1 === current
                    ? "bg-primary tab-active text-white"
                    : "border border-[#EBEBEB]"
                }  py-2 `}
              >
                {i.name}
              </p>
            </div>
          );
        })}
      </div>
      {current === 0 ? (
        <div className="p-4 mt-4 bg-white rounded-lg">
          <Form onFinish={(val) => handleGenerate(val)} labelCol={{ span: 24 }}>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
              <Form.Item
                label="Title"
                name={"title"}
                className="w-full"
                rules={[{ required: true, message: "Select a title" }]}
              >
                <Select
                  className="w-full"
                  options={[
                    {
                      label: "Mr",
                      value: "Mr",
                    },
                    {
                      label: "Mrs",
                      value: "Mrs",
                    },
                    {
                      label: "Miss",
                      value: "Miss",
                    },
                  ]}
                  placeholder={"Select a"}
                />
              </Form.Item>{" "}
              <Form.Item
                label="First Name"
                name={"first_name"}
                rules={[{ required: true, message: "Enter first name" }]}
              >
                <CommonInput placeholder={"Enter first name"} />
              </Form.Item>{" "}
              <Form.Item label="Middle Name" name={"middle_name"}>
                <CommonInput placeholder={"Enter middle name"} />
              </Form.Item>{" "}
              <Form.Item
                label="Last Name"
                name={"last_name"}
                rules={[{ required: true, message: "Enter last name" }]}
              >
                <CommonInput placeholder={"Enter last name"} />
              </Form.Item>{" "}
              <Form.Item
                label="Neame As Per Aadhar"
                name={"aadhar_name"}
                rules={[{ required: true, message: "Enter aadhar name" }]}
              >
                <CommonInput placeholder={"Enter aadhar name"} />
              </Form.Item>{" "}
              <Form.Item
                label="Aadhar Number"
                name={"aadhar_number"}
                rules={[
                  { required: true, message: "Enter aadhar number" },
                  { pattern: aadharPattern, message: "Enter valid aadhar" },
                ]}
              >
                <CommonInput
                  onInput={(e) => {
                    const value = e.target.value.toUpperCase();
                    e.target.value = value;
                  }}
                  placeholder={"Enter aadhar number"}
                />
              </Form.Item>{" "}
              <Form.Item
                label="Mobile"
                name={"mobile"}
                rules={[
                  { required: true, message: "Enter mobile" },
                  { pattern: mobilePattern, message: "Enter valid mobile" },
                ]}
              >
                <CommonInput
                  onInput={(e) => {
                    const value = e.target.value.toUpperCase();
                    e.target.value = value;
                  }}
                  placeholder={"Enter mobile"}
                />
              </Form.Item>{" "}
              <Form.Item
                label="Father Full Name"
                name={"father_name"}
                rules={[{ required: true, message: "Enter father name" }]}
              >
                <CommonInput placeholder={"Enter father name"} />
              </Form.Item>{" "}
              <Form.Item
                label="DOB"
                name={"dob"}
                className="w-full"
                rules={[{ required: true, message: "Enter dob" }]}
              >
                <DatePicker
                  disabledDate={(curr) => curr && curr > dayjs().startOf("day")}
                  className="w-full"
                  placeholder={"Enter dob"}
                />
              </Form.Item>{" "}
              <Form.Item
                label="Full Address"
                name={"full_address"}
                rules={[{ required: true, message: "Enter full address" }]}
              >
                <CommonInput placeholder={"Enter full address"} />
              </Form.Item>{" "}
              <Form.Item
              className="w-full"
                label="Gender"
                name={"gender"}
                rules={[{ required: true, message: "Enter gender" }]}
              >
                <Select
                  options={[
                    { label: "Male", value: "male" },
                    { label: "Female", value: "female" },
                  ]}
                  placeholder={"Select gender"}
                />
              </Form.Item>{" "}
              <Form.Item
                label="Email"
                name={"email"}
                rules={[
                  { required: true, message: "Enter email" },
                  { pattern: emailPattern, message: "Enter valid email" },
                ]}
              >
                <CommonInput placeholder={"Enter email"} />
              </Form.Item>{" "}
              </div>
              <p className="font-bold mt-4">AO Code</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 ">
              <Form.Item
                label="Area Code"
                name={"area_code"}
                rules={[{ required: true, message: "Enter area code" }]}
              >
                <CommonInput placeholder={"Enter area code"} />
              </Form.Item>{" "}
              <Form.Item
                label="Ao Type"
                name={"ao_type"}
                rules={[{ required: true, message: "Enter ao type" }]}
              >
                <CommonInput placeholder={"Enter ao type"} />
              </Form.Item>{" "}
              <Form.Item
                label="Range Code"
                name={"range_code"}
                rules={[{ required: true, message: "Enter range code" }]}
              >
                <CommonInput placeholder={"Enter range code"} />
              </Form.Item>{" "}
              <Form.Item
                label="Ao Number"
                name={"ao_number"}
                rules={[{ required: true, message: "Enter ao number" }]}
              >
                <CommonInput placeholder={"Enter ao number"} />
              </Form.Item>{" "}
            </div>
              <Form.Item label="&nbsp;">
                <Upload
                  beforeUpload={(file) => handleBeforeUpload(file, "new")}
                  showUploadList={false} // Prevents default file list
                  accept=".pdf,.doc" // Restrict file input to PDFs and DOCs only
                  maxCount={1} // Allow only one file
                >
                  <Button
                    className="bg-primary border-none text-white"
                    icon={<UploadOutlined />}
                  >
                    Upload PDF or DOC
                  </Button>
                </Upload>

                {fileData.new && (
                  <div style={{ marginTop: 16 }}>
                    <p>{fileName.new}</p>
                  </div>
                )}
              </Form.Item>
           
            <div className="flex justify-end items-center">
              <Button
                htmlType="submit"
                className="bg-primary text-white outline-none border-none"
              >
                SUBMIT
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <div className="p-4 mt-4 bg-white rounded-lg">
          <Form
            onFinish={(val) => handleCorrection(val)}
            labelCol={{ span: 24 }}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
              <Form.Item
                label="Title"
                name={"title"}
                className="w-full"
                rules={[{ required: true, message: "Select a title" }]}
              >
                <Select
                  className="w-full"
                  options={[
                    {
                      label: "Mr",
                      value: "Mr",
                    },
                    {
                      label: "Mrs",
                      value: "Mrs",
                    },
                    {
                      label: "Miss",
                      value: "Miss",
                    },
                  ]}
                  placeholder={"Select a"}
                />
              </Form.Item>{" "}
              <Form.Item
                label="First Name"
                name={"first_name"}
                rules={[{ required: true, message: "Enter first name" }]}
              >
                <CommonInput placeholder={"Enter first name"} />
              </Form.Item>{" "}
              <Form.Item label="Middle Name" name={"middle_name"}>
                <CommonInput placeholder={"Enter middle name"} />
              </Form.Item>{" "}
              <Form.Item
                label="Last Name"
                name={"last_name"}
                rules={[{ required: true, message: "Enter last name" }]}
              >
                <CommonInput placeholder={"Enter last name"} />
              </Form.Item>{" "}
              <Form.Item
                label="Neame As Per Aadhar"
                name={"aadhar_name"}
                rules={[{ required: true, message: "Enter aadhar name" }]}
              >
                <CommonInput placeholder={"Enter aadhar name"} />
              </Form.Item>{" "}
              <Form.Item
                label="Aadhar Number"
                name={"aadhar_number"}
                rules={[
                  { required: true, message: "Enter aadhar number" },
                  { pattern: aadharPattern, message: "Enter valid aadhar" },
                ]}
              >
                <CommonInput
                  onInput={(e) => {
                    const value = e.target.value.toUpperCase();
                    e.target.value = value;
                  }}
                  placeholder={"Enter aadhar number"}
                />
              </Form.Item>{" "}
              <Form.Item
                label="Mobile"
                name={"mobile"}
                rules={[
                  { required: true, message: "Enter mobile" },
                  { pattern: mobilePattern, message: "Enter valid mobile" },
                ]}
              >
                <CommonInput
                  onInput={(e) => {
                    const value = e.target.value.toUpperCase();
                    e.target.value = value;
                  }}
                  placeholder={"Enter mobile"}
                />
              </Form.Item>{" "}
              <Form.Item
                label="Father Full Name"
                name={"father_name"}
                rules={[{ required: true, message: "Enter father name" }]}
              >
                <CommonInput placeholder={"Enter father name"} />
              </Form.Item>{" "}
              <Form.Item
                label="DOB"
                name={"dob"}
                className="w-full"
                rules={[{ required: true, message: "Enter dob" }]}
              >
                <DatePicker
                  disabledDate={(curr) => curr && curr > dayjs().startOf("day")}
                  className="w-full"
                  placeholder={"Enter dob"}
                />
              </Form.Item>{" "}
              <Form.Item
                label="Full Address"
                name={"full_address"}
                rules={[{ required: true, message: "Enter full address" }]}
              >
                <CommonInput placeholder={"Enter full address"} />
              </Form.Item>{" "}
              <Form.Item
              className="w-full"
                label="Gender"
                name={"gender"}
                rules={[{ required: true, message: "Select gender" }]}
              >
               <Select
                  options={[
                    { label: "Male", value: "male" },
                    { label: "Female", value: "female" },
                  ]}
                  placeholder={"Select gender"}
                />
              </Form.Item>{" "}
              <Form.Item
                label="Email"
                name={"email"}
                rules={[
                  { required: true, message: "Enter email" },
                  { pattern: emailPattern, message: "Enter valid email" },
                ]}
              >
                <CommonInput placeholder={"Enter email"} />
              </Form.Item>{" "}
              <Form.Item
                label=" Pan"
                name={"pan_number"}
                rules={[
                  { required: true, message: "Enter pan" },
                  {
                    pattern: /^[A-Z0-9]{10}$/,
                    message: "Enter a valid PAN in uppercase",
                  },
                ]}
              >
                <CommonInput placeholder={"Enter pan"} />
              </Form.Item>{" "}
              <Form.Item label="&nbsp;">
                <Upload
                  beforeUpload={(file) =>
                    handleBeforeUpload(file, "correction")
                  }
                  showUploadList={false} // Prevents default file list
                  accept=".pdf,.doc" // Restrict file input to PDFs and DOCs only
                  maxCount={1} // Allow only one file
                >
                  <Button
                    className="bg-primary border-none text-white"
                    icon={<UploadOutlined />}
                  >
                    Upload PDF or DOC
                  </Button>
                </Upload>

                {fileData.correction && (
                  <div style={{ marginTop: 16 }}>
                    <p>{fileName.correction}</p>
                  </div>
                )}
              </Form.Item>
            </div>
            <div className="flex justify-end items-center">
              <Button
                htmlType="submit"
                className="bg-primary text-white outline-none border-none"
              >
                SUBMIT
              </Button>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default OfflinePanService;
