import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setHeadings, setselectedTab } from "../Store/B2bslice";
import {
  BsBank,
  BsBank2,
  BsBusFrontFill,
  BsListUl,
  BsPeople,
  BsQrCode,
} from "react-icons/bs";
import {
  FaCertificate,
  FaIdCard,
  FaMoneyBill,
  FaMoneyCheck,
  FaRupeeSign,
} from "react-icons/fa";
import {
  MdAccountBalance,
  MdOutlineFlight,
  MdOutlinePayments,
  MdOutlineTravelExplore,
} from "react-icons/md";
import { TbRecharging } from "react-icons/tb";
import { IoDocumentTextSharp } from "react-icons/io5";
import { BiRun, BiSolidHotel, BiTransferAlt } from "react-icons/bi";
import { FaRegAddressCard } from "react-icons/fa6";
import { FcTodoList } from "react-icons/fc";
import { saveAs } from "file-saver";
import {
  AiOutlineHome,
  AiOutlineHistory,
  AiOutlineWallet,
} from "react-icons/ai";
import { Tooltip } from "antd";
import { getLocalStorageItem } from "../Utils";
import axios from "axios";
import { APIURL } from "../ApiServices/Axios";
import { Globalendpoints } from "../ApiServices/global";
import { ThemeColor } from "../Theme/theme";

export const Menuitems = () => {
  const dispatch = useDispatch();
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const checkStatus = (to) => {
    if (!profiledetail.aeps_onboard) {
      return "/banking/aeps-onboard";
    } else if (!profiledetail.ekyc_status) {
      return "/banking/aeps-ekyc";
    } else if (!profiledetail.twofa_status) {
      return "/banking/aeps";
    } else {
      return to;
    }
  };
  const checkWithdrawalStatus = (to) => {
    if (!profiledetail.aeps_onboard) {
      return "/banking/aeps-onboard";
    } else if (!profiledetail.ekyc_status) {
      return "/banking/aeps-ekyc";
    } else if (!profiledetail.twofa_status) {
      return "/banking/aeps";
    }
    //  else if (withdrawalTime === 0) {
    //   return "/banking/aeps";
    // }
    else {
      return to;
    }
  };
  const checkYesBankStatus = (to) => {
    if (!profiledetail.bank2_aeps_onboard) {
      return "/banking/aepsyes-onboard";
    } else {
      return to;
    }
  };
  const checkDigiStatus = () => {
    if (
      profiledetail.digio_kyc === "0" ||
      profiledetail.digio_kyc === "2" ||
      profiledetail.digio_kyc === "3"
    ) {
      return true;
    }
  };
  const list = [
    {
      key: "1",
      icon: <AiOutlineHome />,
      label: (
        <NavLink to={checkDigiStatus() ? "/kycStatus" : "/dashboard"}>
          <p>Dashboard</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("1"));
        dispatch(setHeadings("Dashboard"));
      },
    },
    {
      key: "2",
      icon: <TbRecharging />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : memberServices.includes("1")
              ? "/recharge"
              : "/not-available"
          }
        >
          <p>Recharge</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("2"));
        dispatch(setHeadings("Recharge & Bill Payment"));
      },
    },
    {
      key: "201",
      icon: <FaMoneyBill />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : memberServices.includes("1")
              ? "/bbps"
              : "/not-available"
          }
        >
          <p>BBPS</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("201"));
        dispatch(setHeadings("Recharge & Bill Payment"));
      },
    },
    {
      key: "3",
      icon: <BsBank />,
      label: (
        <NavLink>
          <p>AEPS</p>
        </NavLink>
      ),
      children: [
        // {
        //   key: "3.1",
        //   icon: <IoFingerPrintSharp />,
        //   label: (
        //     <NavLink to={true ? "/banking/aeps" : "/banking/aeps-ekyc"}>
        //       <p>AEPS ICICI</p>
        //     </NavLink>
        //   ),
        //   onClick: () => {
        //     dispatch(setselectedTab("3.1"));
        //     dispatch(setHeadings("AEPS"));
        //   },
        // },
        {
          key: "3.2",
          icon: <FaRupeeSign />,
          label: (
            <NavLink
              to={
                checkDigiStatus()
                  ? "/kycStatus"
                  : memberServices.includes("8")
                  ? checkWithdrawalStatus("/banking/aeps-cash-withdrawl")
                  : "/not-available"
              }
              state={{ from: "/banking/aeps-cash-withdrawl", type: "aeps" }}
            >
              <p>Cash Withdrawl</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("3.2"));
            dispatch(setHeadings("Cash Withdrawl"));
          },
        },
        {
          key: "3.3",
          icon: <FaRupeeSign />,
          label: (
            <NavLink
              to={
                checkDigiStatus()
                  ? "/kycStatus"
                  : memberServices.includes("8")
                  ? checkWithdrawalStatus("/banking/aeps-aadharpay")
                  : "/not-available"
              }
              state={{ from: "/banking/aeps-aadharpay", type: "aadhar_pay" }}
            >
              <p>Aadhar pay</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("3.3"));
            dispatch(setHeadings("Aadhar Pay"));
          },
        },
        {
          key: "3.4",
          icon: <IoDocumentTextSharp />,
          label: (
            <NavLink
              to={
                checkDigiStatus()
                  ? "/kycStatus"
                  : memberServices.includes("8")
                  ? checkStatus("/banking/aeps-miniStatement")
                  : "/not-available"
              }
              state={{ from: "/banking/aeps-miniStatement", type: "aeps" }}
            >
              <p>Mini Statement</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("3.4"));
            dispatch(setHeadings("Mini statement"));
          },
        },
        {
          key: "3.5",
          icon: <MdAccountBalance />,
          label: (
            <NavLink
              to={
                checkDigiStatus()
                  ? "/kycStatus"
                  : memberServices.includes("8")
                  ? checkStatus("/banking/aeps-balanceEnquiry")
                  : "/not-available"
              }
              state={{ from: "/banking/aeps-balanceEnquiry", type: "aeps" }}
            >
              <p>Balance Enquiry</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("3.5"));
            dispatch(setHeadings("Balance Enquiry"));
          },
        },
      ],
    },
    {
      key: "17",
      icon: <BsBank2 />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : memberServices.includes("20")
              ? checkYesBankStatus("/banking/aeps-yesbank")
              : "/not-available"
          }
        >
          <p>AEPS Bank-2</p>
        </NavLink>
      ),
      // children: [
      //   {
      //     key: "17.2",
      //     icon: <FaRupeeSign />,
      //     label: (
      //       <NavLink
      //         to={
      //           checkDigiStatus()
      //             ? "/kycStatus"
      //             : memberServices.includes("20")
      //             ? checkYesBankStatus("/banking/aepsyes-cash-withdrawl")
      //             : "/not-available"
      //         }
      //         state={{ from: "/banking/aepsyes-cash-withdrawl" }}
      //       >
      //         <p>Cash Withdrawl</p>
      //       </NavLink>
      //     ),
      //     onClick: () => {
      //       dispatch(setselectedTab("17.2"));
      //       dispatch(setHeadings("Cash Withdrawl"));
      //     },
      //   },
      //   {
      //     key: "17.4",
      //     icon: <IoDocumentTextSharp />,
      //     label: (
      //       <NavLink
      //         to={
      //           checkDigiStatus()
      //             ? "/kycStatus"
      //             : memberServices.includes("20")
      //             ? checkYesBankStatus("/banking/aepsyes-miniStatement")
      //             : "/not-available"
      //         }
      //         state={{ from: "/banking/aepsyes-miniStatement"}}
      //       >
      //         <p>Mini Statement</p>
      //       </NavLink>
      //     ),
      //     onClick: () => {
      //       dispatch(setselectedTab("17.4"));
      //       dispatch(setHeadings("Mini statement"));
      //     },
      //   },
      //   {
      //     key: "17.5",
      //     icon: <MdAccountBalance />,
      //     label: (
      //       <NavLink
      //         to={
      //         checkDigiStatus()
      //         ? "/kycStatus"
      //         : memberServices.includes("20")
      //         ? checkYesBankStatus("/banking/aepsyes-balanceEnquiry")
      //         : "/not-available"
      //         }
      //         state={{ from: "/banking/aepsyes-balanceEnquiry"}}
      //       >
      //         <p>Balance Enquiry</p>
      //       </NavLink>
      //     ),
      //     onClick: () => {
      //       dispatch(setselectedTab("17.5"));
      //       dispatch(setHeadings("Balance Enquiry"));
      //     },
      //   },
      // ],
    },
    // {
    //   key: "13",
    //   icon: <BsQrCode />,
    //   label: (
    //     <NavLink
    //       to={
    //         checkDigiStatus()
    //           ? "/kycStatus"
    //           : memberServices.includes("18")
    //           ? "/upi"
    //           : "/not-available"
    //       }
    //     >
    //       <p>UPI</p>
    //     </NavLink>
    //   ),
    //   onClick: () => {
    //     dispatch(setselectedTab("13"));
    //     dispatch(setHeadings("UPI"));
    //   },
    // },
    // {
    //   key: "22",
    //   icon: <BsQrCode />,
    //   label: (
    //     <NavLink
    //       to={
    //         checkDigiStatus()
    //           ? "/kycStatus"
    //           : memberServices.includes("21")
    //           ? "/upi-dmt"
    //           : "/not-available"
    //       }
    //     >
    //       <p>UPI DMT</p>
    //     </NavLink>
    //   ),
    //   onClick: () => {
    //     dispatch(setselectedTab("22"));
    //     dispatch(setHeadings("UPI"));
    //   },
    // },
    {
      key: "10",
      icon: <FaMoneyCheck />,
      label: (
        <NavLink to={"/fundRequest"}>
          <p>Fund Request</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("10"));
        dispatch(setHeadings("Fund Request"));
      },
    },
    {
      key: "106",
      icon: <FaMoneyCheck />,
      label: (
        <NavLink to={"/add-wallet-fund"}>
          <p>Add Wallet Fund</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("106"));
      },
    },
    {
      key: "155",
      icon: <FaIdCard />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : memberServices.includes("101")
              ? "/adhartopan"
              : "/not-available"
          }
        >
          <p>Aadhar To Pan</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("155"));
        dispatch(setHeadings("Adhaar To Pan"));
      },
    },
    {
      key: "105",
      icon: <FaIdCard />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : memberServices.includes("102")
              ? "/pandetails"
              : "/not-available"
          }
        >
          <p>Pan Verification</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("155"));
        dispatch(setHeadings("Adhaar To Pan"));
      },
    },
    {
      key: "7",
      icon: <IoDocumentTextSharp />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : memberServices.includes("16")
              ? "/uti"
              : "/not-available"
          }
        >
          <p>UTI</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("7"));
        dispatch(setHeadings("UTI"));
      },
    },
    {
      key: "16",
      icon: <FaRegAddressCard />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : memberServices.includes("19")
              ? "/panServices"
              : "/not-available"
          }
        >
          <p>Paper-Less PAN Card</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("16"));
        dispatch(setHeadings("Pan Services"));
      },
    },
    {
      key: "160",
      icon: <FaRegAddressCard />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : memberServices.includes("103")
              ? "/offline-panServices"
              : "/not-available"
          }
        >
          <p>Offline Pan Services</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("160"));
      },
    },
    {
      key: "4",
      icon: <BiTransferAlt />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : profiledetail.dmt_kyc === "1" && memberServices.includes("4")
              ? "/dmt/remitters"
              : profiledetail.dmt_kyc !== "1" && memberServices.includes("4")
              ? "/dmt/submitKyc"
              : "/not-available"
          }
        >
          <p>DMT</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("4"));
        dispatch(setHeadings("DMT"));
      },
    },
    {
      key: "5",
      icon: <MdOutlinePayments />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : memberServices.includes("5")
              ? "/payout/verification"
              : "/not-available"
          }
        >
          <p>PayOut</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("5"));
        dispatch(setHeadings("Payout"));
      },
    },
    {
      key: "14",
      icon: <BiRun />,
      label: (
        <NavLink
          to={
            checkDigiStatus()
              ? "/kycStatus"
              : memberServices.includes("9")
              ? "/express-payout"
              : "/not-available"
          }
        >
          <p>Express Payout</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("14"));
        dispatch(setHeadings("Payout"));
      },
    },
    {
      key: "6",
      icon: <MdOutlineTravelExplore />,
      label: (
        <NavLink>
          <p>Travel</p>
        </NavLink>
      ),
      children: [
        {
          key: "6.1",
          icon: <MdOutlineFlight />,
          label: (
            <NavLink
              to={memberServices.includes("14") ? "/flights" : "/coming-soon"}
            >
              <p>Flight</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("6.1"));
            dispatch(setHeadings("Flight ticket"));
          },
        },
        {
          key: "6.2",
          icon: <BsBusFrontFill />,
          label: (
            <NavLink
              to={memberServices.includes("13") ? "/bus" : "/coming-soon"}
            >
              <p>Bus </p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("6.2"));
            dispatch(setHeadings("Bus ticket"));
          },
        },
        {
          key: "6.3",
          icon: <BiSolidHotel />,
          label: (
            <NavLink
              to={memberServices.includes("12") ? "/hotel" : "/coming-soon"}
            >
              <p>Hotel </p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("6.3"));
            dispatch(setHeadings("Hotel Booking"));
          },
        },
      ],
    },
    {
      key: "15",
      icon: <BsPeople />,
      label: (
        <NavLink to={checkDigiStatus() ? "/kycStatus" : "/memberList"}>
          <p>Members</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("15"));
        dispatch(setHeadings("Member List"));
      },
    },
    // {
    //   key: "32",
    //   icon: <AiOutlineWallet />,
    //   label: (
    //     <NavLink to={"/links"}>
    //       <p>Links</p>
    //     </NavLink>
    //   ),
    //   onClick: () => {
    //     dispatch(setselectedTab("32"));
    //   }
    // },
    {
      key: "44",
      icon: <FaRupeeSign />,
      label: (
        <NavLink to={checkDigiStatus() ? "/kycStatus" : "/give-topup"}>
          <p>Give TopUp</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("44"));
      },
    },
    {
      key: "12",
      icon: <AiOutlineWallet />,
      label: (
        <NavLink to={"/Report/wallet"}>
          <p>Wallet</p>
        </NavLink>
      ),
      onClick: () => {
        dispatch(setselectedTab("12"));
        dispatch(setHeadings("Wallet Report"));
      },
    },
    {
      key: "11",
      icon: <AiOutlineHistory />,
      label: <p>Reports</p>,
      children: [
        {
          key: "11.1",
          // icon: <MdAccountBalance />,
          label: (
            <NavLink
              to={checkDigiStatus() ? "/kycStatus" : "/Report/cash-withdrawl"}
            >
              <Tooltip title="CashWithdrawl Report">
                <p>CashWithdrawl</p>
              </Tooltip>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.1"));
            dispatch(setHeadings("Cash Withdrawl Report"));
          },
        },
        {
          key: "11.2",
          // icon: <MdAccountBalance />,
          label: (
            <NavLink
              to={checkDigiStatus() ? "/kycStatus" : "/Report/Mini-statement"}
            >
              <Tooltip title="MiniStatement Report">
                <p>MiniStatement</p>
              </Tooltip>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.2"));
            dispatch(setHeadings("Mini Statement Report"));
          },
        },
        {
          key: "11.10",
          label: (
            <NavLink
              to={checkDigiStatus() ? "/kycStatus" : "/Report/fund-request"}
            >
              <p>Fund Request</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.10"));
            dispatch(setHeadings("Fund Request Report"));
          },
        },
        {
          key: "11.14",
          label: (
            <NavLink to={checkDigiStatus() ? "/kycStatus" : "/Report/yesBank"}>
              <p>Yes Bank AEPS</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.14"));
            dispatch(setHeadings("Yes Bank AEPS"));
          },
        },
        {
          key: "11.3",
          // icon: <MdAccountBalance />,
          label: (
            <NavLink
              to={checkDigiStatus() ? "/kycStatus" : "/Report/aadharpay"}
            >
              <Tooltip title="MiniStatement Report">
                <p>Aadhar Pay</p>
              </Tooltip>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.3"));
            dispatch(setHeadings("Aadhar Pay Report"));
          },
        },
        {
          key: "11.12",
          // icon: <MdAccountBalance />,
          label: (
            <NavLink
              to={checkDigiStatus() ? "/kycStatus" : "/Report/balance-enquirey"}
            >
              <Tooltip title="Balance Enquirey">
                <p>Balance Enquirey</p>
              </Tooltip>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.12"));
            dispatch(setHeadings("Balance Enquirey Report"));
          },
        },
        {
          key: "11.51",
          label: (
            <NavLink to={checkDigiStatus() ? "/kycStatus" : "/Report/add-fund"}>
              <p>Add Fund</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.51"));
          },
        },
        {
          key: "11.5",
          label: (
            <NavLink to={checkDigiStatus() ? "/kycStatus" : "/Report/recharge"}>
              <p>Recharge</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.5"));
            dispatch(setHeadings("Recharge Report"));
          },
        },
        {
          key: "11.4",
          label: (
            <NavLink to={checkDigiStatus() ? "/kycStatus" : "/Report/payout"}>
              <p>Payout</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.4"));
            dispatch(setHeadings("Payout Report"));
          },
        },
        {
          key: "11.11",
          label: (
            <NavLink
              to={checkDigiStatus() ? "/kycStatus" : "/Report/xpressPayout"}
            >
              <p>XpressPayout</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.11"));
          },
        },
        {
          key: "11.30",
          // icon: <MdAccountBalance />,
          label: (
            <NavLink
              to={checkDigiStatus() ? "/kycStatus" : "/Report/aadhartopan"}
            >
              <Tooltip title="CashWithdrawl Report">
                <p>AadharToPan</p>
              </Tooltip>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.30"));
            dispatch(setHeadings("Cash Withdrawl Report"));
          },
        },
        {
          key: "11.55",
          // icon: <MdAccountBalance />,
          label: (
            <NavLink
              to={checkDigiStatus() ? "/kycStatus" : "/Report/pandetails"}
            >
              <Tooltip title="CashWithdrawl Report">
                <p>PanVerification</p>
              </Tooltip>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.55"));
            dispatch(setHeadings("Cash Withdrawl Report"));
          },
        },
        {
          key: "11.6",
          label: (
            <NavLink to={checkDigiStatus() ? "/kycStatus" : "/Report/bbps"}>
              <p>BBPS</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.6"));
            dispatch(setHeadings("BBPS Report"));
          },
        },
        {
          key: "11.7",
          label: (
            <NavLink to={checkDigiStatus() ? "/kycStatus" : "/Report/dmt"}>
              <p>Dmt</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.7"));
            dispatch(setHeadings("DMT Report"));
          },
        },
        {
          key: "11.8",
          label: (
            <NavLink to={checkDigiStatus() ? "/kycStatus" : "/Report/uti"}>
              <p>Uti</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.8"));
            dispatch(setHeadings("Uti Report"));
          },
        },
        // {
        //   key: "11.13",
        //   label: (
        //     <NavLink to={checkDigiStatus() ? "/kycStatus" : "/Report/nsdl"}>
        //       <p>Nsdl</p>
        //     </NavLink>
        //   ),
        //   onClick: () => {
        //     dispatch(setselectedTab("11.13"));
        //     dispatch(setHeadings("Nsdl Report"));
        //   },
        // },
        {
          key: "11.130",
          label: (
            <NavLink
              to={checkDigiStatus() ? "/kycStatus" : "/Report/offline-nsdl"}
            >
              <p>Offline Nsdl</p>
            </NavLink>
          ),
          onClick: () => {
            dispatch(setselectedTab("11.130"));
          },
        },
        // {
        //   key: "11.15",
        //   label: (
        //     <NavLink to={checkDigiStatus() ? "/kycStatus" : "/Report/upi-dmt"}>
        //       <p>Upi DMT</p>
        //     </NavLink>
        //   ),
        //   onClick: () => {
        //     dispatch(setselectedTab("11.15"));
        //     dispatch(setHeadings("Upi Dmt Report"));
        //   },
        // },
      ],
    },
    {
      key: "101",
      icon: <FaCertificate />,
      label: <p>Download Certificate</p>,
      onClick: () => {
        dispatch(setselectedTab("101"));
        axios
          .get(
            APIURL +
              Globalendpoints.downLoadCertificate +
              `?primary_color=` +
              ThemeColor.primary.replace("#", "") +
              "&secondary_color=" +
              ThemeColor.primaryhover.replace("#", ""),
            {
              responseType: "blob",
              headers: {
                "Content-Type": "application/pdf",
                // Range: "bytes=0-999999",
                Authorization: `Bearer ${getLocalStorageItem("access_token")}`,
              },
            }
          )
          .then((res) => {
            saveAs(res.data, "certificate.pdf");
          })
          .catch((err) => console.log(err));
      },
    },
  ];
  if (profiledetail.member_type?.toLowerCase() !== "Retailer".toLowerCase()) {
    return list;
  } else {
    const filter = list.filter((i) => i.key !== "15" && i.key !== "44");
    return filter;
  }
};
