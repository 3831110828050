import {
  Button,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { FaRegAddressCard } from "react-icons/fa";
import { ThemeColor } from "../../../Theme/theme";
import NsdlReport from "../Reports/NsdlReport";
import { nsdlRequest } from "../../../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setselectedTab } from "../../../Store/B2bslice";

const PanServices = () => {
  const [btnLoading, setBtnLoading] = useState({
    generateNewPanReqest: false,
    generateCorrectionRequest: false,
  });
  const [selectedOption, setSelectedOption] = useState(true);
  const [selected, setSelected] = useState("")
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGenerate = (key) => {
    const formdata = new FormData()
    formdata.append("is_esign_request", selectedOption)
    setBtnLoading({ ...btnLoading, [key]: true });
    nsdlRequest(key, formdata)
      .then((res) => {
        if (res.status) {
          window.open(res.data.redirect_url, "_blank");
        }
      })
      .catch((err) => console.log(err))
      .finally(() =>
        setBtnLoading({
          generateNewPanReqest: false,
          generateCorrectionRequest: false,
          generateCrReq: false,
        })
      );
  };
  useEffect(() => {
    dispatch(setselectedTab("16"));
    if (!memberServices.includes("19")) {
      navigate("/not-Available");
    }
  }, []);
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 place-items-center">
        <section class="container w-full">
          <div class="card w-full">
            <div class="content">
              <FaRegAddressCard color={ThemeColor.primary} size={50} />
              <h6>New Pan </h6>
              <div class="">
                <div className="flex justify-end w-full gap-4 items-center mt-6">
                  {/* <Select onChange={val=>setSelectedOption(val)} value={selectedOption} placeholder="Select Option" options={[
                  {
                    label:"Scan Based",value:true
                  },
                  {
                    label:"E-kyc",value:false
                  }
                ]}/> */}
                  <Button
                    loading={btnLoading.generateNewPanReqest}
                    onClick={() =>{ setSelectedOption(true);setSelected("Photo Sign E-Kyc")}}
                    className={` ${selected == "Photo Sign E-Kyc" ? "bg-black": "bg-primary"} outline-none border-none text-white hover:outline-none focus:border-none`}
                  >
                    Photo Sign E-Kyc
                  </Button>
                  <Button
                    loading={btnLoading.generateNewPanReqest}
                    onClick={() => {setSelectedOption(false);setSelected("E-Kyc Pan")}}
                    className={` ${selected == "E-Kyc Pan" ? "bg-black": "bg-primary"} outline-none border-none text-white hover:outline-none focus:border-none`}
                  >
                    E-Kyc Pan
                  </Button>
                  <Button
                    loading={btnLoading.generateNewPanReqest}
                    onClick={() => handleGenerate("generateNewPanReqest")}
                    className="bg-primary outline-none border-none text-white hover:outline-none focus:border-none"
                  >
                    Generate Pan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="container">
          <div class="card">
            <div class="content">
              <FaRegAddressCard color={ThemeColor.primary} size={50} />
              {/* <img class="logo" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/76882/logo.svg" alt="mparticle"/> */}
              <h6>Correction Pan </h6>
              <div class="">
                <div className="flex justify-end w-full gap-4 items-center mt-6">
                  {/* <Select onChange={val=>setSelectedOption(val)} value={selectedOption} placeholder="Select Option" options={[
                  {
                    label:"Scan Based",value:true
                  },
                  {
                    label:"E-kyc",value:false
                  }
                ]}/> */}
                  <Button
                    loading={btnLoading.generateNewPanReqest}
                    onClick={() =>{ setSelectedOption(true);setSelected("Photo Sign E-Kyc")}}
                    className={` ${selected == "Photo Sign E-Kyc" ? "bg-black": "bg-primary"} outline-none border-none text-white hover:outline-none focus:border-none`}
                  >
                    Photo Sign E-Kyc
                  </Button>
                  <Button
                    loading={btnLoading.generateNewPanReqest}
                    onClick={() => {setSelectedOption(false);setSelected("E-Kyc Pan")}}
                    className={` ${selected == "E-Kyc Pan" ? "bg-black": "bg-primary"} outline-none border-none text-white hover:outline-none focus:border-none`}
                  >
                    E-Kyc Pan
                  </Button>
                  <Button
                    loading={btnLoading.generateCorrectionRequest}
                    onClick={() => handleGenerate("generateCorrectionRequest")}
                    className="bg-primary outline-none border-none text-white hover:outline-none focus:border-none"
                  >
                    Generate Pan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div></div>
      </div>
      <NsdlReport />
    </>
  );
};

export default PanServices;
