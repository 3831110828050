import React, { useEffect } from "react";
import { useState } from "react";
import YesWithdrawalMoney from "./WithdrawalMoney";
import YesBalanceEnquiry from "./BalanceEnquiry";
import YesMinistatement from "./Ministatement";
import { useDispatch, useSelector } from "react-redux";
import TwoFaModal from "./TwoFaModal";
import { setselectedTab } from "../../../../../Store/B2bslice";
import { useNavigate } from "react-router-dom";
import UserTwoFaModal from "./UserTwofaModal";

const AepsYesMainScreen = () => {
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const [type, setType] = useState("");
  const navigate = useNavigate();
  const [withdrawalpayload, setwithdrawalPayload] = useState(null);
  const [openUserTwofaModal, setOpenUserTwoFaModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [response, setResponse] = useState({});
  const [twofatxnId, setTwoFatxnId] = useState(null);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const Tabs = [
    {
      key: 1,
      name: "Cashwithdrawal",
      component: (
        <YesWithdrawalMoney
          type={type}
          response={response}
          setwithdrawalPayload={setwithdrawalPayload}
          setType={setType}
          withdrawalpayload={withdrawalpayload}
          setOpenReceiptDrawer={setOpenReceiptDrawer}
          openReceiptDrawer={openReceiptDrawer}
          setOpenModal={setOpenModal}
          twofatxnId={twofatxnId}
          current={current}
          setCurrent={setCurrent}
        />
      ),
    },
    {
      key: 2,
      name: "Balance Enquiry",
      component: (
        <YesBalanceEnquiry
          setOpenModal={setOpenModal}
          current={current}
          setCurrent={setCurrent}
        />
      ),
    },
    {
      key: 3,
      name: "Mini Statement",
      component: (
        <YesMinistatement
          setOpenModal={setOpenModal}
          current={current}
          setCurrent={setCurrent}
        />
      ),
    },
  ];
  useEffect(() => {
    dispatch(setselectedTab("17"));
    if (memberServices.includes("20")) {
      if (
        Object.keys(profiledetail).length !== 0 &&
        !profiledetail.bank2_aeps_onboard
      ) {
        navigate("/banking/aepsyes-onboard");
      }
    } else {
      navigate("/not-available");
    }
  }, []);
  useEffect(() => {
    setType("");
  }, [current]);
  return (
    <>
      <div className="mt-8 hidden bg-white rounded-lg md:grid grid-cols-1 md:grid-cols-3 place-items-start md:place-items-center gap-y-4">
        {Tabs.map((i, index) => {
          return (
            <div className="w-full text-center">
              <p
                onClick={() => {
                  setCurrent(index);
                }}
                className={`cursor-pointer transition-all  ${
                  i.key - 1 === current
                    ? "bg-primary tab-active text-white"
                    : "border border-[#EBEBEB]"
                }  py-2 `}
              >
                {i.name}
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-2  md:hidden grid grid-cols-3  place-items-center md:place-items-center gap-4">
        {Tabs.map((i, index) => {
          return (
            <div className="w-full text-center">
              <p
                onClick={() => {
                  setCurrent(index);
                }}
                className={`w-full relative ${
                  i.key - 1 === current
                    ? "border-b tab-active border-b-primary text-primary"
                    : "border-b text-gray-500 border-b-gray-500"
                } cursor-pointer  py-2 `}
              >
                {i.name}
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-4">{Tabs[current].component}</div>
      {openModal && (
        <TwoFaModal
          openUserTwofaModal={openUserTwofaModal}
          setOpenUserTwoFaModal={setOpenUserTwoFaModal}
          type={type}
          setType={setType}
          current={current}
          setTwoFatxnId={setTwoFatxnId}
          isOpen={openModal}
          setIsOpen={setOpenModal}
        />
      )}
      {openUserTwofaModal && (
        <UserTwoFaModal
          setResponse={setResponse}
          setOpenReceiptDrawer={setOpenReceiptDrawer}
          isOpen={openUserTwofaModal}
          withdrawalpayload={withdrawalpayload}
          setIsOpen={setOpenUserTwoFaModal}
          twofatxnId={twofatxnId}
          current={current}
          openTwoFaModal={openModal}
          setOpenTwoFaModal={setOpenModal}
          type={type}
          setType={setType}
          setTwoFatxnId={setTwoFatxnId}
        />
      )}
    </>
  );
};

export default AepsYesMainScreen;
